// Triple hyphens on lines 1 and 2 are required by Jekyll in order to
// process SCSS into CSS.
// 
// Note that your code editor may syntax highlight the first import statement
// below as being in an error state due to the hyphens above which are part of
// Jekyll syntax and not SCSS syntax. An invalid syntax error, especially on
// the first meaningful line of code, might be a false positive.

// dependencies imports
@import '_modules/core/_utils';
@import '_modules/core/_media';
@import '_modules/theme/_config';

.svg__accent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  max-width: auto;
}
.js-menu-trigger-is-inverted {
  body {
    &.home {
      #menu-trigger {
        background: $color-white;
        svg {
          circle {
            fill: $color-black;
            @include media-below(1200) {
              fill: $color-black;
            }
          }
        }
      }
    }
  }
}
.js-menu-is-active {
  body {
    &.home {
      #menu-trigger {
        svg {
          circle {
            fill: $color-black;
            @include media-below(1200) {
              fill: $color-white;
            }
          }
        }
      }
    }
  }
}

body {
  &.home {
    .header {
      position: absolute;
      @include media-mobile-only {
        position: fixed;
      }
      &__content {
        background-color: transparent;
        box-shadow: inherit;
        &--column {
          svg {
            fill: $color-white;
          }
          .btn {
            color: $color-white!important;
            border-color: $color-white;
            &:hover {
              border-color: $color-black;
            }
          }
        }
      }
    }
    #menu-trigger {
      svg {
        circle {
          fill: $color-white;
          @include media-mobile-only {
            fill: $color-black;
          }
        }
      }
      &:hover {
        svg {
          circle {
            fill: $color-accent;
          }
        }
      }
    }
    .section__banner {
      height: 100vh;
      max-height: inherit;
      &--block {
        margin-top: 13rem;
        @include media-tablet-only {
          margin-top: 9rem;
        }
        @include media-desktop-only {
          margin-top: 16rem;
        }
        @include media-above-laptop {
          margin-top: 20rem;
        }
      }
    }
  }
}
.section__banner {
  height: 100vh;
  //max-height: 43.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: center top;
  @include media-mobile-only {
    padding-top: 0!important;
  }
  @include media-below-laptop {
    padding-top: 60px;
  }
  @include media-below-desktop {
    height: 60vh!important;
  }
  @include media-laptop-only {
    height: 80vh!important;
  }
  &--block {
    z-index: 5;
    position: relative;
    margin-top: 10rem;
    margin-left: 2rem;
    margin-right: auto;
    margin-bottom: auto;
    @include media-tablet-only {
      margin-top: 9rem;
      margin-left: 5rem;
    }
    //@include media-laptop-only {
    //  margin-top: 16rem;
    //}
    //@include media-desktop-only {
    //  margin-top: auto;
    //}

    @include media-desktop-only {
      margin-top: 16rem;
      margin-left: 7rem;
    }
    @include media-above-laptop {
      margin-top: 20rem;
      margin-left: 10rem;
    }

    h1 {
      margin: 0 auto;
    }
    a {
      &.btn {
        background-color: transparent;
        margin: 2rem auto 0;
        display: inline-block;
        overflow: inherit;
        padding: 0;
        @include media-desktop-only {
          margin: 4rem auto 0;
        }
        &:after {
          background-color: transparent;
        }
        &:hover {
          &:after {
            background-color: transparent;
          }
        }
      }
    }
    img {
      max-width: 340px;
    }
  }
  nav {
    ul, ol {
      list-style: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.3125rem;
      gap: 1.6875rem;
      li {
        position: relative;
        font-family: $body-font;
        color: $color-white;
        font-size: 16*$pxToRem;
        font-weight: 600;
        font-style: normal;
        letter-spacing: 0.05em;
        line-height: 1.7142857143;
        text-transform: uppercase;
        //@include media-mobile-only {
        //  font-size: 17*$pxToRem;
        //  line-height: 1.25;
        //}
        @include media-above-mobile {
          font-size: 18*$pxToRem;
        }
        &:not(:last-child) {
          &:before {
            display: block;
            position: absolute;
            right: -0.6375rem;
            top: 0.2rem;
            content: "|";
            width: 0.1875rem;
            height: 0.1875rem;
            color: $color-white;
            border-radius: 100%;
            @include media-mobile-only {
              top: 0.275rem;
            }
          }
        }
        a {
          color: $color-white;
          font-weight: 600;
          font-style: normal;
          display: block;
          padding: 0.3125rem 0.25rem;
          &:hover {
            color: $color-accent;
          }
          @include media-mobile-only {
            font-size: 17*$pxToRem;
            line-height: 1.25;
          }
        }
      }
    }
  }
}

.section__blocks {
  h2 {
    margin-top: 0;
    margin-bottom: 0;
  }
  h3 {
    margin: 0;
  }
  li {
    &::marker {
      color: $color-accent;
    }
  }
  .padding-section-content {
    @include media-mobile-only {
      padding-top: 3rem!important;
      padding-bottom: 3rem!important;
    }
  }
  &.location {
    .grid-2-col {
      grid-gap: 10px;
    }
  }
  .menu-img {
    max-width: 500px;
    margin: auto;
  }

  .gallery-img {
    max-height: 30vh;
    margin: 0 auto;
  }

  .svg__accent {
    &.block {
      top: 50%;
      transform: translateY(-50%);
      max-width: max-content!important;
      z-index: -1;
    }
  }
}

.section__welcome {
  @include media-tablet-only {
    .width-1920 {
      grid-template-columns: 1fr;
      img {
        width: 70%;
        margin: 0 auto;
      }
    }
  }
}

.section__meet {
  .svg__accent {
    top: -7rem;
    left: auto;
    right: 0;
    width: 20%;
    svg {
      width: 50%;
    }
    @include media-tablet-only {
      top: -7rem;
      right: 2rem;
    }
    @include media-desktop-only {
      top: -5rem;
      right: 7rem;
    }
    &.bottom {
      top: inherit;
      left: auto;
      right: 0;
      bottom: -6rem;
      width: 20%;
      svg {
        width: 50%;
      }
      @include media-tablet-only {
        top: inherit;
        right: 2rem;
      }
      @include media-desktop-only {
        top: inherit;
        right: 7rem;
      }
    }
  }
  .width-1920 {
    margin-left: 1rem;
    margin-right: 1rem;
    @include media-tablet-only {
      grid-template-columns: 1fr;
    }
    @include media-desktop-only {
      margin-left: 4rem;
      margin-right: 4rem;
    }
    .padding-top-100 {
      background-color: rgba($color-white,.6);
      padding: 2rem;
      @include media-desktop-only {
        background-color: transparent;
        padding: 0;
      }
      &.inverted {
        background-color: rgba($color-black,.6);
        @include media-desktop-only {
          background-color: transparent;
          p {
            padding: 0;
          }
        }
      }
    }
  }
  .grid-2-col {
    p {
      padding-right: 0;
    }
  }
  p {
    padding-right: 0;
    @include media-desktop-only {
      padding-right: 8rem;
    }
  }
}

.section__reviews {
  p {
    font-size: 27*$pxToRem;
    font-weight: 700;
  }
  a {
    svg {
      opacity: .3;
      transition: all $transition-default;
    }
    &:hover {
      svg {
        opacity: 1;
      }
    }
  }
}

.section__procs {
  h2 {
    font-size: 40*$pxToRem;
  }
  .grid {
    margin-top: -4rem;
    margin-bottom: 14rem;
    @include media-tablet-only {
      margin-top: 0;
    }
    @include media-desktop-only {
      margin-bottom: 20rem;
    }
    img {
      margin-bottom: -10rem;
      @include media-desktop-only {
        margin-bottom: -16rem;
      }
    }
  }
  &--column {
    min-height: 36rem;
    &.width-1920 {
      margin-left: 0;
      margin-right: 0;
      @include media-tablet-only {
        grid-template-columns: 8fr 6fr;
        .grid-2-col {
          grid-template-columns: 1fr;
        }
      }
      @include media-desktop-only {
        margin-left: 4rem;
        margin-right: 4rem;
      }
    }
    .grid-2-col {
      height: 100%;
      .padding-top-100 {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        p {
          padding-right: 4rem;
        }
      }
    }
    .menu__list {
      background-color: $color-white;
      padding: 4rem 5rem;
      position: relative;
      bottom: 0;
      right: 0;
      min-width: 180px;
      @include media-tablet-only {
        padding: 3rem;
      }
      @include media-above-mobile {
        position: absolute;
      }
    }
  }
  &--left {
    .width-1920 {
      margin-right: 0;
      @include media-above-mobile {
        grid-gap: 80px;
      }
      @include media-desktop-only {
        margin-right: 20%;
      }
      .menu__list {
        margin-top: 2rem;
        margin-left: 1rem;
        @include media-desktop-only {
          margin-top: 0;
          margin-left: 0;
        }
      }
    }
  }
  &--right {
    .width-1920 {
      margin-left: 0;
      @include media-above-mobile {
        grid-gap: 80px;
      }
      @include media-desktop-only {
        margin-left: 20%;
      }
      .menu__list {
        margin-top: 2rem;
        margin-left: 1rem;
        @include media-desktop-only {
          margin-top: 0;
          margin-left: 0;
        }
      }
    }
  }
  &--one {
    img {
      margin-bottom: 0;
      @include media-above-mobile {
        margin-bottom: -14rem;
      }
    }
  }
  &--two {
    &-box {
      width: 100%;
      height: auto;
      position: relative;
      overflow: hidden;
      transition: all $transition-default;
      &:hover {
        .menu__list {
          opacity: 1;
          transform: translateY(0);
        }
        img {
          transform: scale(1.2);
          //box-shadow: 0 0 0 60px rgba($bg-blue,.6);
        }
      }
      .menu__list {
        background-color: rgba($color-white,.85);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        opacity: 0;
        transform: translateY(100%);
        transition: all $transition-default;
      }
      img {
        transition: all $transition-default;
      }
    }
  }
}



//.section__procs {
//  .grid {
//    height: 100%;
//  }
//  &--lasers {
//    img {
//      height: auto;
//      max-height: 300px;
//      @include media-above-mobile {
//        height: 100%;
//        max-height: inherit;
//      }
//    }
//  }
//  .specials {
//    @include media-below-desktop {
//      grid-template-columns: 1fr;
//      .menu__procs {
//        display: none;
//      }
//    }
//  }
//  .box-bg {
//    //order: 2;
//    padding: 4rem 2rem!important;
//    @include media-desktop-only {
//      //order: inherit;
//      padding: $padding-default-vw!important;
//    }
//  }
//  .padding-left-column {
//    padding-left: 0;
//    @include media-desktop-only {
//      padding-left: 4rem;
//    }
//    @include media-above-laptop {
//      padding-left: 10rem;
//    }
//  }
//  .padding-right-column {
//    padding-right: 0;
//    z-index: -1;
//    @include media-desktop-only {
//      padding-right: 4rem;
//      margin-left: -6rem;
//    }
//    @include media-above-laptop {
//      padding-right: 10rem;
//      margin-left: -6rem;
//    }
//  }
//  .padding-right-30 {
//    padding-right: 0;
//    @include media-desktop-only {
//      padding-right: 30px;
//    }
//  }
//  .margin-procs {
//    margin-bottom: 0;
//    @include media-tablet-only {
//      margin-bottom: -2rem;
//      margin-left: -4rem;
//      margin-right: -4rem;
//      z-index: 10;
//    }
//    @include media-desktop-only {
//      margin-bottom: -4rem;
//    }
//  }
//}

//.section__team {
//  &--img {
//    margin-top: 7rem;
//    @include media-above-mobile {
//      margin-top: 0;
//    }
//  }
//  .border-tr {
//    margin-right: 0;
//    @include media-desktop-only {
//      margin-right: 4rem
//    }
//    @include media-above-laptop {
//      margin-right: 10rem
//    }
//  }
//}

//.section__wellness {
//  &--column {
//    &.reverse {
//      @include media-tablet-only {
//        grid-template-columns: 10fr 7fr;
//      }
//      @include media-laptop-only {
//        grid-template-columns: 8fr 7fr;
//      }
//      @include media-above-laptop {
//        grid-template-columns: 6fr 7fr;
//      }
//      .section__contact--column-align {
//        margin-top: 0;
//        margin-left: 2rem;
//        margin-right: 2rem;
//        @include media-tablet-only {
//          margin-left: 4rem;
//          margin-right: 0;
//        }
//        @include media-laptop-only {
//          margin-left: 4rem;
//          margin-right: 0;
//        }
//        @include media-above-laptop {
//          margin-left: 6rem;
//          margin-right: 0;
//        }
//      }
//    }
//
//    @include media-tablet-only {
//      grid-template-columns: 7fr 10fr;
//    }
//    @include media-laptop-only {
//      grid-template-columns: 7fr 8fr;
//    }
//    @include media-above-laptop {
//      grid-template-columns: 7fr 6fr;
//    }
//  }
//}

//.section__gallery {
//  .grid-2-col-8-3 {
//    grid-template-columns: 1fr;
//    @include media-desktop-only {
//      grid-template-columns: 8fr 3fr;
//    }
//    div {
//      &:nth-child(1) {
//        position: relative;
//        margin-right: 0;
//        @include media-desktop-only {
//          margin-right: 6rem;
//        }
//        @include media-above-laptop {
//          margin-right: 8rem;
//        }
//        .padding-section-content {
//          margin-right: 0;
//          margin-top: 2rem;
//          padding: 4rem 2rem!important;
//          @include media-tablet-only {
//            margin-top: -4rem;
//          }
//          @include media-desktop-only {
//            margin-right: 10rem;
//            margin-top: 8rem;
//            padding: 8rem 7rem!important;
//          }
//          @include media-above-laptop {
//            margin-right: 30rem;
//            margin-top: 8rem;
//            padding: 8rem 7rem!important;
//          }
//        }
//        img {
//          position: relative;
//          top: 0;
//          right: 0;
//          max-width: 100%;
//          z-index: -1;
//          @include media-desktop-only {
//            top: -3rem;
//            position: absolute;
//            max-width: 420px;
//          }
//          @include media-above-laptop {
//            top: -6rem;
//            max-width: 570px;
//          }
//        }
//      }
//      &:nth-child(2) {
//        padding: $padding-default-vw;
//        @include media-desktop-only {
//          padding: 0;
//        }
//        h2 {
//          margin-bottom: 3rem;
//        }
//        p, a {
//          padding-right: 4rem;
//        }
//      }
//    }
//  }
//}
.section__media {
  img {
    width: auto;
    max-height: 52px;
    margin: 0 auto;
  }
}

.section__contact {
  &:after {
    background: linear-gradient(0deg, rgba($color-black,0.2) 0%, rgba($color-black,0.4) 50%, rgba($color-black,0.4) 60%, rgba($color-black,0) 100%);
  }
  &--column {
    position: relative;
    z-index: 10;
    min-height: 30rem;
    h2 {
      a {
        color: $color-white;
        &:hover {
          color: $color-accent;
        }
      }
    }
    .svg__accent {
      top: 50%;
      transform: translateY(-50%);
      opacity: .2;
      z-index: -1;
    }
  }
}

.section__cc {
  &--column {
    position: relative;
    z-index: 10;
    min-height: 22rem;
  }
}

.section__resource {
  background-color: $bg-gray;
  .grid-2-col {
    div {
      &:nth-child(1) {
        display: flex;
        align-items: center;
        p {
          font-size: 14*$pxToRem;
          margin: 0;
        }
      }
      &:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 3rem;
        a {
          svg {
            fill: $color-white;
            border-radius: 50%;
            border: 1px solid $color-white;
            transition: all $transition-default;
          }
          &:hover {
            svg {
              fill: $color-accent;
              border-color: $color-accent;
            }
          }
        }
      }
    }
  }
  .grid-3-col {
    grid-gap: 30px;
  }
  &--box {
    padding-top: 2.5rem;
    padding-bottom: 2rem;
    border-top: 1px solid rgba($color-white, .15);
    border-bottom: 1px solid rgba($color-white, .15);
    p {
      margin: 1rem 0;
    }
  }
}

.section__resources {
  .width-1640 {
    .grid-2-col {
      grid-gap: 30px;
    }
    &.grid-4-col {
      grid-gap: 30px;
    }
  }
  &--box {
    background-color: $color-gray-1;
    color: $color-white;
    cursor: pointer;
    font-size: 16*$pxToRem;
    font-weight: 600;
    min-height: 90px;
    padding: 3rem 4rem 4rem;
    position: relative;
    z-index: 10;
    overflow: hidden;
    text-transform: uppercase;
    &:before {
      background-color: $color-accent;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: all $transition-default;
      width: 10px;
    }
    &:hover {
      &:before {
        width: 100%;
      }
      .relative {
        svg {
          opacity: 0.3;
        }
      }
    }
    &-align {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 10;
      svg {
        margin-top: 0;
        display: block;
      }
    }
    .relative {
      svg {
        fill: $color-white;
        opacity: 0.08;
        position: absolute;
        right: -3rem;
        top: -0.5rem;
        transition: all $transition-default;
      }
    }
  }
}

.location__box {
  background-color: $color-gray-1;
  width: 50%;
  max-width: 450px!important;
  padding: 4rem 3rem;
  margin: -7rem auto 5rem;
  z-index: 10;
  position: relative;
  h2 {
    font-family: $body-font;
    font-size: 18*$pxToRem;
    color: $color-white;
    font-weight: 600;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  p {
    margin-top: 0;
    a {
      color: $color-white;
      font-weight: 400;
      &:hover {
        color: $color-accent;
      }
    }
  }
}

.section__footer {
  background-color: $bg-gray;
  padding: 2rem 0 4rem;
  @include media-above-mobile {
    padding: 0;
  }
  &--column {
    min-height: 18rem;
    grid-template-columns: 1fr;
    .align-left {
      margin-bottom: 2rem;
    }
    @include media-above-laptop {
      grid-template-columns: 1fr 1fr;
    }
    &-align {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      text-align: center;
      @include media-above-laptop {
        text-align: inherit;
      }
    }
    .social {
      ul {
        justify-content: center;
        margin-top: 2rem;
        margin-bottom: 0;
        @include media-above-laptop {
          justify-content: flex-start;
          margin-top: 0;
        }
        li {
          margin-right: 0;
          a {
            &:hover {
              svg {
                fill: $color-accent;
              }
            }
            svg {
              fill: $color-white;
            }
          }
        }
      }
    }
    .phone {
      text-align: center;
      margin-bottom: 3rem;
      //@include media-above-mobile {
      //  text-align: right;
      //}
      @include media-above-laptop {
        //margin: 0;
        text-align: right;
      }
      a, span {
        color: $color-white;
        font-size: 27*$pxToRem;
        font-weight: 700;
        display: inline-block;
        transition: all $transition-default;
        //&:hover {
        //  color: $color-accent;
        //}
      }
      span {
        margin: 0 0.2rem;
      }
    }
    ul {
      list-style: none;
      margin: 0;
      margin-bottom: 2rem;
      padding: 0;
      li {
        list-style: none;
        display: inline-block;
        margin-left: .5rem;
        margin-right: .5rem;
        font-size: 15*$pxToRem;
        @include media-desktop-only {
          margin-left: inherit;
          margin-right: 2rem;
          font-size: 16*$pxToRem;
        }
        &:last-child {
          margin-right: 0;
        }
        a {
          color: $color-white;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }
    a {
      color: $color-white;
      transition: all $transition-default;
      &:hover {
        color: $color-accent;
      }
    }
  }
}

#main-contact-form,
#newsletter-signup {

  input,
  select,
  textarea {
    height: unset;
  }

  select {
    color: #767676;
  }

  input[type="checkbox"] {
    all: revert;
  }

  .wpcf7-not-valid-tip {
    margin: 1rem auto;
  }

  .wpcf7-acceptance {

    .wpcf7-list-item {
      margin: 0 0 1rem;
    }
  }

  input[type="submit"] {
    align-items: center;
    background-color: transparent;
    border: 1px solid #000;
    border-radius: 50px;
    color: #000!important;
    display: flex;
    font-family: proxima-nova,sans-serif;
    font-weight: 400;
    justify-content: center;
    min-height: 2.25rem;
    overflow: hidden;
    padding: .375rem 1.5625rem!important;
    position: relative;
    text-transform: uppercase;
    transition: all .3s ease-in;
    width: -moz-max-content;
    width: max-content;
    z-index: 0;
  }

  .consent {
    margin: 1rem auto;
    display: block;
    text-align: center;
  }

}

@include media-above-mobile {
.innerpage {
  .header {
    top: 0;
  }
  .section__banner {
    margin-top: 6.25rem;
  }
}
}